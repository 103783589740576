<template>
  <div class="declared_oasis_explan">
    <img v-if="getContentUrl" class="declared_oasis_explan__inner" :src="getContentUrl" alt="">
    <img v-else class="declared_oasis_explan__inner" src="@/img/img-declared-oasis-explan.jpeg" alt="">
  </div>
</template>

<script>
  export default {
    computed: {
      getContentUrl () {
        return this.$route.query.contentUrl || ''
      }
    }
  }
</script>
<style scoped>
.declared_oasis_explan__inner {
  max-width: 100%;
  object-fit: contain;
  vertical-align: middle;
}
</style>
